<template>
  <el-dialog
    append-to-body
    width="800px"
    :title="`${isChange ? '修改' : '新增'}`"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-form-item prop="title" label="标题">
        <el-input placeholder="请输入标题" v-model="form.data.title" clearable></el-input>
      </el-form-item>
      <el-form-item prop="content" label="描述">
        <my-editor ref="editor" :editorTxt="this.form.data.content"></my-editor>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  import common from '@/util'
  import {getStorage} from '@/storage'
  import MyEditor from "@/components/MyEditor.vue";
  export default {
    name: 'NotifyEdit',
    components: {
      MyEditor,
    },
    data() {
      return {
        token: getStorage('token'),
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            title: '',
            content: '',
          },
          rules: {
            // content: [{ required: true, message: '请输入', trigger: 'change' }],
            title: [{ required: true, message: '请输入', trigger: 'change' }],
            
          }
        },
      }
    },
    methods: {
      // 获取详情
      getDetail(row) {
        console.log(row,888)
        this.isChange = true
        this.$http.get('/admin/notice/info', {params:{id: row.id} }).then(res => {
          common.deepClone(this.form.data, res.data)
        })
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.form.loading = true;
           this.form.data.content = this.$refs.editor.editorContent;
            if (!this.form.data.content) {
              this.$message.warning("描述不能为空");
              return;
            }
            console.log(this.form.data)
           let apiUrl = !this.isChange ? '/admin/notice/add' : '/admin/notice/edit'
            this.$http.post(apiUrl, this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      },
    }
  }
</script>
